.btn-circle {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 0;
  border-width: 2px;
  line-height: 1.42857;
  margin-top: 0.5rem;
}

.btn-circle.btn-xl {
  width: 60px;
  height: 60px;
  border-radius: 35px;
  font-size: 30px;
  line-height: 1.33;
}

.btn-circle:hover {
  color: #F04875;
}

.about-me {
	color: white;
}

.card-opacity {
  background-color: rgba(255, 255, 255, 0.3);
}

.am-card-title {
  font-size: x-large;
  font-weight: 00;
  font-size: 27px;
  line-height: 33px;
  color: white;
}

.card-text {
	color: white;
}

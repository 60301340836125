h1 {
  color: white;
  font-size: 100px;
}

article {
  color: white;
  font-size: xx-large;
}

.header-main {
  padding-top: 18rem;
  margin-bottom: 8rem;
}

.down-arrow {
  margin-top: 5rem;
  text-align: center;
}

/* MOBILE STYLING */
.mobile-h1 {
  color: white;
  font-size: 50px;
}

.mobile-article {
  color: white;
  font-size: x-large;
}

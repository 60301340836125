.skills {
  margin-bottom: 10rem;
  padding-top: 3rem;
}

.center {
  text-align: center;
}

.skills-text {
  color: white;
  margin-top: 0.7em;
}

.all-skills {
  margin-left: -3em;
}

.skills-img {
  min-height: 5rem;
  display: flex;
}

.btn-pill {
  width: 100%;
  max-width: 200px;
  height: 60px;
  padding: 10px 16px;
  text-align: center;
  border-radius: 35px;
  text-align: center;
  font-size: x-large;
  line-height: 1.42857;
  border-width: 2px;
  margin-top: 0.5rem;
}

.btn-pill:hover {
  color: #F04875;
}

.full-btn {
  width: 95%;
  max-width: none;
}

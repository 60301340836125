h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  color: #FFFFFF;
}

.projects {
  margin-bottom: 10rem;
  padding-top: 2rem;
}

.card-space {
  padding-right: 15px;
}

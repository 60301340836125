.card-more {
  border-radius: 15px;
  padding: 0.5rem;
  cursor: pointer;
  min-height: 20rem;
}

.card-title {
  font-size: x-large;
  font-weight: 00;
  font-size: 27px;
  line-height: 33px;
  color: #F04875;
}

.tag {
  text-align: left;
  padding-left: 2rem;
  padding-right: 2rem;
}

.card-image {
  padding-top: 1rem;
  height: 10rem;
  width: auto;
}

.awards {
  font-weight: 500;
  line-height: 16px;
}

.back {
  text-align: left;
}

.card-subtitle {
  font-weight: 500;
  line-height: 18px;
  margin-top: 1em;
  margin-bottom: 0.35em;
  color: #F04875;
}

.button-center {
  text-align: center;
}
